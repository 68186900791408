/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /** HAMBURGER / MAIN NAV **/
        $('#main_header .hamburger').on('click', function(){

          // hamburger
          $(this).stop().fadeOut(300, function(){
            if($(this).hasClass('active')){
              $(this).removeClass('active');
            } else {
              $(this).addClass('active');  
            }
            $(this).fadeIn();
          });

          // main nav
          $('#main_nav').stop().slideToggle();

          $('#main_nav li.menu-item-has-children > a').each(function(){
            if($(this).find('span').length === 0){
              $(this).append('<span></span>');
            }
          });
        });

        $('#main_nav li.menu-item-has-children > a').on('click', function(e){
          if($(window).width() <= 991){
            e.preventDefault();

            $(this).toggleClass('active');

            var this_href = $(this).attr('href'),
                this_text = $(this).html();
            
            if ($(this).parent().find('ul.sub-menu').first().find('.cloned').length === 0){
              $(this).parent().find('ul.sub-menu').first().prepend('<li class="cloned"><a href="'+this_href+'">'+this_text+'</a></li>');
            }
            $(this).parent().find('ul.sub-menu').first().stop().slideToggle();
          }
        });
        /** END HAMBURGER / MAIN NAV **/


        /** BACK TO TOP **/
        $('#back_to_top').on('click', function(){
          $('html, body').animate({scrollTop: 0}, 400);
        });

        $(window).scroll(function() {
          if($(document).scrollTop() > 350){
            $('#back_to_top').addClass('active');
          } else {
            $('#back_to_top').removeClass('active');
          }
        });
        /** END BACK TO TOP **/

        
        /** LINKS SCROLL TO CLASS **/
        $('.links a').on('click', function(e){
          e.preventDefault();

          $('html,body').animate({
            scrollTop: $('#main_footer').offset().top - 50
          });
        });
        /** END LINKS SCROLL TO CLASS **/


        /** REDUCE HEADER SIZE / HEADER SCROLL **/
        var last_scroll_top = 0;

        $(window).scroll(function() {
          if($(window).width() > 991){

            // header size
            if($(document).scrollTop() > 50){
              $('body').addClass('header_reduced');
            } else {
              $('body').removeClass('header_reduced');
            }
          }

          if($(document).scrollTop() > 100){
            var st = $(this).scrollTop();

            if (st > last_scroll_top){
              // downscroll code
              $('#main_header').addClass('down_scroll');
            } else {
              // upscroll code
              $('#main_header').removeClass('down_scroll');
            }
            last_scroll_top = st;
          }
        });
        /** END REDUCE HEADER SIZE / HEADER SCROLL **/


        /** ACCORDION **/
        $('.accordion ul li').each(function(){
          $(this).find('.visible').click(function(){
            $(this).parent().toggleClass('active');
            $(this).parent().find('.reveal').slideToggle();
          });
        });
        /** END ACCORDION **/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // sizing
    'lularoe_styles_sizing_pricing': {
      init: function() {
        
        // sizing filter
        $('#sizing .filter ul li').on('click', function(){
          var this_cat = $(this).attr('data-category');

          $('#sizing .nav li').hide();
          $('#sizing .charts li').hide();

          if($(this).hasClass('active')){
            $(this).removeClass('active');
            $('#sizing .nav li').show();
            $('#sizing .charts li').show();
          } else {
            $('#sizing .filter ul li').removeClass('active');
            $(this).addClass('active');

            $('#sizing .nav li').hide();
            $('#sizing .charts li').hide();

            $('#sizing .nav li.'+this_cat).show();
            $('#sizing .charts li.'+this_cat).show();
          }
        });

        // JavaScript to be fired on the about us page
        $('#sizing .nav li a').each(function(){
          $(this).click(function(e){
            e.preventDefault();
            var id = $(this).attr('href'),
                style = $(this).attr('data-style');

            //alert(style);

            // FIRE FACEBOOK PIXEL EVENT
            fbq('trackCustom', 'SizeRequest', {
              style: style
            });
            // END FB PIXEL

            // url hash
            if(history.pushState) {
              history.pushState(null, null, id);
            }
            else {
              location.hash = id;
            }

            $(document).scrollTop( $('.charts '+id).offset().top - 2);
          });
        });

        // youtube
        $('#sizing .charts li .youtube').each(function(){
          $(this).on('click', function(){
            var youtube_id = $(this).attr('data-youtube'),
                youtube_src = 'https://www.youtube.com/embed/'+youtube_id+'?rel=0';

            if(youtube_id){
              $('#youtube_popup iframe').attr('src', youtube_src);
              $('#youtube_popup').fadeIn();
            }

            $('#youtube_popup .closer, #youtube_popup .trans_bg').on('click', function(){
              $('#youtube_popup iframe').attr('src', '');
              $('#youtube_popup').fadeOut();
            });
          });
        });

        // back to top button
        $('#sizing .charts .diff_link').each(function(){
          $(this).click(function(e){
            e.preventDefault();
            $(document).scrollTop( $('#sizing').offset().top - 75);
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
